import { cond, equals, always } from 'ramda'

const feedIcon = cond([
  [equals('quit'), always('sign out')],
  [equals('start'), always('play circle')],
  [equals('finish'), always('check circle')],
  [equals('photos'), always('photo')],
  [equals('open-web'), always('linkify')],
  [equals('form-sended'), always('file text')],
  [equals('send-point-of-sale'), always('shopping bag')],
  [equals('mission_address'), always('unordered list')],
  [equals('check_in'), always('map marker alternate')],
  [equals('check_out'), always('map marker alternate')],
])
const feedLabel = cond([
  [equals('quit'), always('Desistiu da missão')],
  [equals('start'), always('Iniciou a missão')],
  [equals('finish'), always('Finalizou a missão')],
  [equals('photos'), always('Enviou Foto')],
  [equals('open-web'), always('Anexo Aberto')],
  [equals('form-sended'), always('Enviou Formulário')],
  [equals('send-point-of-sale'), always('Enviou um Produto')],
  [equals('mission_address'), always('Endereço da Missão')],
  [equals('check_in'), always('Registro de Entrada')],
  [equals('check_out'), always('Registro de Saída')],
])
const iconColor = cond([
  [equals('quit'), always('red')],
  [equals('start'), always('blue')],
  [equals('finish'), always('green')],
  [equals('photos'), always('teal')],
  [equals('open-web'), always('teal')],
  [equals('form-sended'), always('yellow')],
  [equals('send-point-of-sale'), always('purple')],
  [equals('mission_address'), always('blue')],
  [equals('check_in'), always('green')],
  [equals('check_out'), always('red')],
])
const statusPaymentColor = cond([
  [equals(0), always('yellow')],
  [equals(1), always('green')],
  [equals(2), always('red')],
])
const statusPaymentLabel = cond([
  [equals(0), always('Aguardando Avaliação')],
  [equals(1), always('Aprovado')],
  [equals(2), always('Cancelado')],
])
const statusRegisterLabel = cond([
  [equals(0), always('Aguardando Avaliação')],
  [equals(1), always('Aprovado')],
  [equals(2), always('Reprovado')],
  [equals(3), always('Validando Dados')],
  [equals(4), always('Em Correção')],
])

const statusRegisterColor = cond([
  [equals(0), always('#fbbd08')],
  [equals(1), always('#21ba45')],
  [equals(2), always('#db2828')],
  [equals(3), always('#f2711c')],
  [equals(4), always('#2185d0')],
])

export {
  feedIcon,
  feedLabel,
  iconColor,
  statusRegisterLabel,
  statusRegisterColor,
  statusPaymentColor,
  statusPaymentLabel,
}
