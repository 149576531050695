import React, { useEffect, useState } from 'react'
import { FlyToInterpolator } from 'react-map-gl'

import * as d3 from 'd3'
import t from 'prop-types'
import { Grid } from 'semantic-ui-react'

import useMissionsFeeds from '~/hooks/useMissionsFeeds'
import Main from '~/pages/Main'

import Feeds from './Feeds'
import Info from './Info'
import Map from './Map'

const Activities = ({ id, place, lat, lng }) => {
  const { feeds, getMissionsFeeds, updateMissionsFeeds, removeMissionsFeeds } =
    useMissionsFeeds()
  const [viewport, setViewport] = useState({
    zoom: 14,
    latitude: lat || 0,
    longitude: lng || 0,
    transitionDuration: 5000,
    transitionEasing: d3.easeCubic,
    transitionInterpolator: new FlyToInterpolator(),
  })
  const [openEdit, setOpenEdit] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)

  function onClickAction({ lat, lng }) {
    return setViewport(prevState => ({
      ...prevState,
      zoom: 14,
      latitude: lat,
      longitude: lng,
      transitionDuration: 5000,
      transitionEasing: d3.easeCubic,
      transitionInterpolator: new FlyToInterpolator(),
    }))
  }

  function onChangeMap(viewportData) {
    return setViewport(prevState => ({ ...prevState, ...viewportData }))
  }

  const handleOnSubmitUpdate = async params => {
    await updateMissionsFeeds({
      ...params.values,
      missionsFeedsId: params.missionsFeedsId,
    })
  }

  const handleOnClickOpenRemove = () => {
    setOpenRemove(true)
  }

  const handleOnSubmitRemove = item => {
    removeMissionsFeeds(item)

    setOpenRemove(false)
  }

  useEffect(() => {
    getMissionsFeeds(id)
  }, []) //eslint-disable-line

  return (
    <Main>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={5}>
            <Feeds
              items={feeds}
              onClick={onClickAction}
              missionsUsersId={id}
              onSubmitUpdate={handleOnSubmitUpdate}
              onClickCloseEdit={() => setOpenEdit(false)}
              onClickOpenEdit={() => setOpenEdit(true)}
              onClickOpenRemove={handleOnClickOpenRemove}
              onClickCloseRemove={() => setOpenRemove(false)}
              openEdit={openEdit}
              openRemove={openRemove}
              onSubmitRemove={handleOnSubmitRemove}
            />
          </Grid.Column>
          <Grid.Column width={11}>
            <Map items={feeds} viewport={viewport} onChange={onChangeMap} />
            <Info place={place} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Main>
  )
}

Activities.propTypes = {
  id: t.string.isRequired,
  place: t.string,
  lat: t.number,
  lng: t.number,
}

export default Activities
